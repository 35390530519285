import React from "react";
import {
    Navbar,
    Container
} from "react-bootstrap";


import "../../asset/css/Header.css";

function Header() {

    return (
        <div>
            <Navbar className="my-navbar">
                <Container className="justify-content-center">
                    <Navbar.Brand href="/invitation">
                        <img src="/images/Logo5.png" alt="logo"/>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;