import React from "react";
import "../asset/css/Rdv.css"
import Countdown2 from "../asset/components/Countdown2";

const Rdv = () => {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = -30;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
                reveals[i].classList.add("fade-bottom");
            } else {
                reveals[i].classList.remove("active");
                reveals[i].classList.remove("fade-bottom");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    return (
        <div className="background-color-countdown">
            <div className="rdv reveal">
                RENDEZ VOUS DANS
            </div>
            <div className="count-direction reveal">
                <Countdown2 />
            </div>
        </div>
    );
}

export default Rdv;