import React from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";

import "../../asset/css/CarteReponse.css";


function CarteReponse() {


    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = -30;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
                reveals[i].classList.add("fade-bottom");
            } else {
                reveals[i].classList.remove("active");
                reveals[i].classList.remove("fade-bottom");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    const [Nom, setName] = useState('');
    const [Nombre, setNumber] = useState('');
    const [Mairie, setAttendanceTownHall] = useState(false);
    const [Houppa, setAttendanceReception] = useState(false);
    const [Message, setMessage] = useState('');
    
    const handleSubmit = async (event) => {
        
        console.log(JSON.stringify({ Nom, Nombre, Houppa , Mairie, Message }));
        setAttendanceTownHall(false);
        fetch('https://api-mariage-ye-d7qkdt7ybq-od.a.run.app/reponse/add', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Nom, Nombre, Houppa , Mairie, Message }),
        });
        alert('Merci de votre reponse!');
    };

    return (
        <Card className="fond-invitation-reponse">
            <div className="rsvp-form-container">
                <form onSubmit={handleSubmit} className="rsvp-form">
                    <label className="form-label reveal">Votre nom et prénom</label>
                    <input
                        className="form-input reveal"
                        type="text"
                        value={Nom}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />

                    <label className="form-label reveal">Nombre de personnes</label>
                    <input
                        className="form-input reveal"
                        type="number"
                        value={Nombre}
                        onChange={(e) => setNumber(e.target.value)}
                        required
                    />

                    {/* <label className="form-label reveal">Assistera(ont) à la Mairie du 18 avril 2024</label> */}
                    {/* <div className="radio-group reveal">
                        <label className="radio-label reveal">
                            <input
                                type="radio"
                                name="AttendanceTownHall"
                                value="oui"
                                onChange={(e) => setAttendanceTownHall(true)}
                                required
                            />
                            Oui
                        </label>
                        <label className="radio-label reveal">
                            <input
                                type="radio"
                                name="AttendanceTownHall"
                                value="non"
                                onChange={(e) => setAttendanceTownHall(false)}
                            />
                            Ne pourra(ont) pas assister
                        </label>
                    </div> */}

                    <label className="form-label reveal">Assistera(ont) à la Houppa et Réception du 03 juin 2024</label>
                    <div className="radio-group reveal">
                        <label className="radio-label reveal">
                            <input
                                type="radio"
                                name="attendanceReception"
                                value="oui"
                                onChange={(e) => setAttendanceReception(true)}
                                required
                            />
                            Oui
                        </label>
                        <label className="radio-label reveal">
                            <input
                                type="radio"
                                name="attendanceReception"
                                value="non"
                                onChange={(e) => setAttendanceReception(false)}
                            />
                            Ne pourra(ont) pas assister
                        </label>
                    </div>

                    <label className="form-label reveal">Message pour les mariés</label>
                    <textarea
                        className="form-textarea reveal"
                        value={Message}
                        onChange={(e) => setMessage(e.target.value)}
                    />

                    <button type="submit" className="form-button reveal">Envoyer</button>
                </form>
            </div>
        </Card>
    );
}

export default CarteReponse;