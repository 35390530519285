import React from "react";
import '../asset/css/Footer.css';

function Footer() {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = -30;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
                reveals[i].classList.add("fade-bottom");
            } else {
                reveals[i].classList.remove("active");
                reveals[i].classList.remove("fade-bottom");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    return (
        <div className="footer">
            <div className="footer-event ">
                <p>Copyriht© 2024 - Tous droits réservés</p>
            </div>

            <div className="footer-logo ">
                <img src="/images/Logo5.png" alt="logo"/>
            </div>

            <div className="footer-link ">
                <p> Made by <a href="https://instagram.com/eitanamram?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr">Eitan Amram</a></p>
            </div>
        </div>
    );
}

export default Footer;