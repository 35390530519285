import React from "react";
import { Button } from 'react-bootstrap';
import Countdown from "../asset/components/Countdown";
import "../asset/css/Home.css";


const Home = () => {
    return (
        <div className="background">

            <div className="corp">

                <div className="titre">
                    <div className="titre-Acceuil">
                        Mariage
                    </div>
                    <div className="titre-Nom">
                        Yona & Eitan
                    </div>
                </div>

                <div className="countdown-main">
                    <div className="empty"></div>
                    <div className="countdown">
                        <Countdown />
                    </div>
                    <div className="empty2"></div>
                </div>

                <div className="lien">
                    <Button href="/Invitation" variant="outline-light" className="bouton-log">Voir l'invitation</Button>
                </div>

            </div>

        </div>
    );
}
export default Home;