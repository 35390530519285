import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {ReactComponent as Phrase} from "../svg/phrase.svg";
 
import "../css/CarteHouppa.css"

function CarteHouppa() {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = -30;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
                reveals[i].classList.add("fade-bottom");
            } else {
                reveals[i].classList.remove("active");
                reveals[i].classList.remove("fade-bottom");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    return (

        <div className="carte-houppa">
            <Phrase className="reveal corps"/>
            <div className="en-tete-carte">
                <div className="famille1 reveal">
                    <div>
                        M et Mme David et Chirley Timstite
                    </div>
                    <div>
                        M et Mme Moshé et Judith Botbol
                    </div>
                </div>
                <div className="famille2 reveal">
                    <div>
                        M et Mme Claude et Michele Amram
                    </div>
                    <div>
                        Mme Elise Guerin
                    </div>
                    <div>
                        M et Mme David et Jacqueline Amram
                    </div>
                </div>
            </div>
            <div className="info-houppa reveal">
                Ont l'immense joie de vous faire part du mariage de leurs petits-enfants et enfants
            </div>
            <div className="nom-houppa reveal">
                Yona & Eitan
            </div>
            <div className="nom-houppa-hebreu reveal">
                איתן & יונה
            </div>
            <div className="info-houppa reveal">
                Et vous prient de bien vouloir les honorer de votre présence à la houppa qui sera célébrée
            </div>
            <div className="info-houppa reveal">
                Le lundi 3 juin 2024 à 18h30
            </div>
            <div className="info-houppa reveal">
                כ"ו אייר 5784 - 18:30
            </div>
            <div className="info-houppa reveal">
                À la Salle
            </div>
            <div className="info-houppa-salle reveal">
                Kahi Event Resort - Hadera, Israel
            </div>
            <div className="info-houppa-salle-hebreu reveal">
                קאי ריזורט אירועים - חדרה, ישראל
            </div>
            <div className="info-houppa reveal">
                La cérémonie sera suivie de la récéption
            </div>
            <div className="info-houppa reveal">
                "En ce jour si particulier, nos pensées sont dirigées vers nos chers grands parents, Papi yaacov, Mamie estrella et Papi Maurice"
            </div>
            <Dropdown className="button-itineraire-houppa">
                <Dropdown.Toggle variant="transparent">
                    Comment y aller ?
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark">
                    <Dropdown.Item href="https://ul.waze.com/ul?ll=32.39733000%2C34.89530300&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location" target="_blank">Waze</Dropdown.Item>
                    <Dropdown.Item href="https://maps.app.goo.gl/yf2id9VQhWKQ43428" target="_blank">Google Map</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default CarteHouppa;