import React, { useEffect } from "react";
import "../css/Countdown2.css";
import $ from 'jquery';

function Countdown2() {
  useEffect(() => {
    $(function () {

      function format(v) {
        return (v.toString().length === 1) ? '0' + v : v;
      }

      const now = new Date('June 03, 2024, 17:00:00');

      let currentDate = Date.now();
      const endDateString = now;
      const endDateTime = Date.parse(endDateString);
      const endDate = new Date(endDateTime);

      const $days = $('.days2');
      const $hours = $('.hours2');
      const $mins = $('.minutes2');
      const $secs = $('.seconds2');

      setInterval(function () {

        currentDate = Date.now();
        if (currentDate < endDate) {

          const time = endDate - currentDate;

          const seconds = Math.floor((time / 1000) % 60);
          const minutes = Math.floor((time / 60000) % 60);
          const hours = Math.floor((time / 3600000) % 24);
          const days = Math.floor((time / 86400000));

          $secs.text(format(seconds));
          $mins.text(format(minutes));
          $hours.text(format(hours));
          $days.text(days);

        }

      }, 100);

    });
  })

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = -30;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
        reveals[i].classList.add("fade-bottom");
      } else {
        reveals[i].classList.remove("active");
        reveals[i].classList.remove("fade-bottom");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <>
      <div className="countdown2">
        <div className="days-container2 reveal">
          <div className="days2"></div><div className="days-letter2">j</div>
        </div>
        <div className="hours-container2 reveal">
          <div className="hours2"></div><div className="hours-letter2">h</div>
        </div>
        <div className="minutes-container2 reveal">
          <div className="minutes2"></div><div className="minutes-letter2">m</div>
        </div>
        <div className="seconds-container2 reveal">
          <div className="seconds2"></div><div className="seconds-letter2">s</div>
        </div>
      </div>
    </>
  );
}

export default Countdown2;