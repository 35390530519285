import React from "react";
import Footer from "../components/Footer";
import SaveTheDate from "../components/SaveTheDate";
import Rdv from "../components/Rdv";
import Mairie from "../components/Mairie";
import Houppa from "../components/Houppa";
import Soiree from "../components/Reponse";

function Invitation() {
    return (
        <div>
            <SaveTheDate />
            <Rdv />
            {/* <Mairie /> */}
            <Houppa />
            <Soiree />
            <Footer />
        </div>
    );
}

export default Invitation;