import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import PublicRoutes from './routes/PublicRoutes';

function App() {
  const meta = {
    title: 'Yona&Eitan',
    description: 'Bienvenue sur  le site du mariage de Yona & Eitan',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  return (
    <>
      {/* <DocumentMeta {...meta} /> */}
      <BrowserRouter>
        <PublicRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
