import React from "react";

import "../asset/css/Houppa.css"

import CarteHouppa from "../asset/components/CarteHouppa";

function Houppa() {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = -30;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
                reveals[i].classList.add("fade-bottom");
            } else {
                reveals[i].classList.remove("active");
                reveals[i].classList.remove("fade-bottom");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    return (
        <div className="Houppa">
            {/* <div className="titre-Houppa reveal">
                Houppa
            </div> */}
            <div className="fond-invitation-houppa reveal">
                <CarteHouppa />
            </div>
        </div>
    );
}

export default Houppa;