import React from "react";

import Home from "../pages/Home";
import Invitation from "../pages/Invitation";

import { Route, Routes, useParams} from "react-router-dom";

import Cookies from "universal-cookie";

function PublicRoutes() {

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/invitation" element={<Invitation />} />
        </Routes>
    );
}

export default PublicRoutes;
