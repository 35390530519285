import React, { useEffect } from "react";
import "../css/Countdown.css";
import $ from 'jquery';

function Countdown() {
  useEffect(() => {
    $(function() {
  
      function format(v) {
        return (v.toString().length === 1) ? '0' + v : v;
      }
      
      const now = new Date('June 03, 2024, 17:00:00');
      
      let currentDate = Date.now();
      const endDateString = now;
      const endDateTime = Date.parse(endDateString);
      const endDate = new Date(endDateTime);
      
      const $days = $('.days');
      const $hours = $('.hours');
      const $mins = $('.minutes');
      const $secs = $('.seconds');
      
      setInterval(function() {
        
        currentDate = Date.now();
        if (currentDate < endDate) {
        
          const time = endDate - currentDate;
        
          const seconds = Math.floor((time / 1000) % 60);
          const minutes = Math.floor((time / 60000) % 60);
          const hours = Math.floor((time / 3600000) % 24);
          const days = Math.floor((time / 86400000));
        
          $secs.text( format(seconds) );
          $mins.text( format(minutes) );
          $hours.text( format(hours) );
          $days.text( days );
          
       }
        
      }, 100);
      
    });
  })

  return (
    <>
      <div className="countdown">
        <div className="days-container">
          <div className="days"></div><div className="days-letter">j</div>
        </div>
        <div className="hours-container">
          <div className="hours"></div><div className="hours-letter">h</div>
        </div>
        <div className="minutes-container">
          <div className="minutes"></div><div className="minutes-letter">m</div>
        </div>
        <div className="seconds-container">
          <div className="seconds"></div><div className="seconds-letter">s</div>
        </div>
      </div>
    </>
  );
}

export default Countdown;