// RSVPForm.js
import React from 'react';
import "../asset/css/Reponse.css";

import CarteReponse from '../asset/components/CarteReponse';

const RSVPForm = () => {

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = -50;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
        reveals[i].classList.add("fade-bottom");
      } else {
        reveals[i].classList.remove("active");
        reveals[i].classList.remove("fade-bottom");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <div className='reponse-container'>
      <div className='reponse-titre'>
        <div className='form-title reveal'>
          Réponse
        </div>
        <div className='form-instruction reveal'>
          Réponse souhaitée dès réception de l'invitation
        </div>
      </div>
      <div className='reveal'>
        <CarteReponse />
      </div>
    </div>
  );
};

export default RSVPForm;
