import React from "react";
import "../asset/css/SaveTheDate.css";
import Header from "../asset/components/Header";


const SaveTheDate = () => {

    return (
        <div className="save-the-date">
            <div className="save-the-date-Logo active fade-bottom">
                <Header />
            </div>
            <div className="save-the-date-titre active fade-bottom">
                Mariage
            </div>
            <div className="save-the-date-Nom active fade-bottom">
                Yona & Eitan
            </div>
        </div>
    );
};

export default SaveTheDate;